import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import TextPageComponent from "../components/shared/text-page"
import { Link } from "gatsby"

const PolicyPage = () => (
  <Layout forceFixedHeader={true} page={"policy"}>
    <SEO title="Политика конфиденциальности" noindex="noindex"/>
    <TextPageComponent title={"Политика конфиденциальности в отношении обработки персональных данных"}>
      <p>
        Настоящая Политика конфиденциальности в отношении персональных данных (далее –
        Политика конфиденциальности) действует в отношении всей информации, которую интернет-сайт, расположенный на
        доменном имени paraplancrm.ru, может получить о Пользователе во
        время использования Сайта.
      </p>
      <ol className={"ordered-list"}>
        <li>
          <h3>Определение терминов</h3>
          <p>В настоящей политике конфиденциальности используются следующие термины:</p>
          <ol>
            <li>
              <i>Администрация сайта</i> – уполномоченные на управление сайтом сотрудники Компании,
              действующие от имени Компании, которые организуют и (или) осуществляют обработку
              персональных данных, а также определяют цели обработки персональных данных, состав
              персональных данных, подлежащих обработке, действия (операции), совершаемые с
              персональными данными;
            </li>
            <li>
              <i>Пользователь</i> — физическое лицо, заполнившее форму обратной связи или иным образом
              предоставившее свои персональные данные посредством функционала сайта.
            </li>
            <li>
              <i>Персональные данные</i> — любая информация, относящаяся к прямо или косвенно
              определенному или определяемому физическому лицу (субъекту персональных данных);
            </li>
            <li>
              <i>Обработка персональных данных</i> — любое действие (операция) или совокупность действий
              (операций), совершаемых с использованием средств автоматизации или без использования таких
              средств с персональными данными, включая сбор, запись, систематизацию, накопление,
              хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание,
              блокирование, удаление, уничтожение персональных данных;
            </li>
            <li>
              <i>Автоматизированная обработка персональных данных</i> — обработка персональных данных с помощью средств
              вычислительной техники;
            </li>
            <li>
              <i>Конфиденциальность персональных данных</i> — обязательное для соблюдения требование не
              допускать распространение персональных данных без согласия субъекта персональных данных
              или наличия иного законного основания;
            </li>
            <li>
              <i>Cookies</i> — небольшой фрагмент данных, отправленный веб-сервером и хранимый на
              компьютере Пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в
              HTTP-запросе при попытке открыть страницу соответствующего Сайта;
            </li>
            <li>
              <i>IP-адрес</i> — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
            </li>
          </ol>
        </li>
        <li>
          <h3>Общие положения</h3>
          <ol>
            <li>Сайт, расположенный на доменном имени paraplancrm.ru принадлежит ООО «Холмонт БР», ИНН 6321416763
              (Компания).
            </li>
            <li>Принимая условия настоящей Политики, Пользователь подтверждает своё согласие на сбор
              и обработку определённой информации о нем в целях, установленных в настоящей Политике, и
              в соответствии с ней. Использование Сайта означает безоговорочное согласие с настоящей
              Политикой и указанными в ней условиями обработки персональной информации.
            </li>
            <li>В случае несогласия Пользователя с условиями Политики, Пользователь обязан
              незамедлительно прекратить использование Сайта, а также воздержаться от передачи Компании
              каких-либо данных о себе.
            </li>
            <li>Администрация сайта не проверяет достоверность персональных данных, предоставляемых
              Пользователем сайта Компании.
            </li>
            <li>Пользователь осознает и принимает возможность использования на Сайте программного
              обеспечения третьих лиц, в результате чего такие лица могут получать и передавать указанные
              в пп. 3.2.1 – 3.2.5. данные в обезличенном виде. К указанному программному обеспечению
              третьих лиц относятся системы по сбору статистики посещений: Google Analytics, Яндекс.Метрика и иные
              системы.
            </li>
            <li>Настоящая Политика применяется только к данному Сайту Компании. Администрация сайта
              не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь
              может перейти по ссылкам, доступным на Сайте.
            </li>
            <li>Настоящая Политика регулируется и толкуется в соответствии с законодательством
              Российской Федерации. Вопросы, не урегулированные Политикой, подлежат разрешению в
              соответствии с законодательством Российской Федерации.
            </li>
          </ol>
        </li>
        <li>
          <h3>Предмет политики конфиденциальности</h3>
          <ol>
            <li>Настоящая Политика конфиденциальности устанавливает обязательства Администрации
              сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных
              данных, которые Пользователь предоставляет по запросу Администрации сайта при оформлении
              заказа для приобретения Товара.
            </li>
            <li>Настоящая Политика конфиденциальности устанавливает обязательства Администрации
              сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных
              данных, которые Пользователь предоставляет по запросу Администрации сайта при оформлении
              заказа для приобретения Товара.
            </li>
            <li>Персональные данные, разрешённые к обработке в рамках настоящей Политики
              конфиденциальности, предоставляются Пользователем путём заполнения форм обратной связи
              Сайта, и могут включать в себя следующую информацию:
              <ol>
                <li>имя Пользователя;</li>
                <li>наименование организации, представляемой Пользователем;</li>
                <li>контактный телефон Пользователя;</li>
                <li>адрес электронной почты (e-mail);</li>
                <li>а также иную информацию.</li>
              </ol>
            </li>
            <li>
              Сайт защищает данные, которые автоматически передаются в процессе просмотра
              рекламных блоков и при посещении страниц, на которых установлен статический скрипт
              системы:
              <ol>
                <li>IP адрес;</li>
                <li>информация из cookies;</li>
                <li>информация о браузере;</li>
                <li>время доступа;</li>
                <li>реферер (адрес предыдущей страницы);</li>
              </ol>
            </li>
            <li>Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация
              используется с целью выявления и решения технических проблем.
            </li>
            <li>Любая иная персональная информация, не оговоренная выше, подлежит надежному
              хранению и нераспространению, за исключением случаев, предусмотренных в п. 5.4 настоящей
              Политики конфиденциальности.
            </li>
          </ol>
        </li>
        <li>
          <h3>Цели сбора персональной информации пользователя</h3>
          <ol>
            <li>
              Персональные данные Пользователя Администрация Сайта может использовать в целях:
              <ol>
                <li>Идентификации Пользователя, для оформления заказа и (или) заключения Договора
                  дистанционным способом.
                </li>
                <li>Установления с Пользователем обратной связи, включая направление уведомлений,
                  запросов, оказания услуг, обработки запросов и заявок от Пользователя.
                </li>
                <li>Предоставления Пользователю эффективной клиентской и технической поддержки при
                  возникновении проблем, связанных с использованием Сайта.
                </li>
                <li>Предоставления Пользователю (с его согласия) обновлений продукции, специальных
                  предложений, информации о ценах, и иных сведений от имени Сайта или от имени партнеров.
                </li>
                <li>Осуществления рекламной деятельности с согласия Пользователя.</li>
                <li>Предоставления доступа Пользователю на сайты или сервисы партнеров с целью
                  получения продуктов, обновлений и услуг.
                </li>
                <li>Формирования статистики, касающейся использования Сайта Пользователями,
                  составления базы данных Пользователей и иных целей, связанных с систематизацией
                  полученных данных в интересах Компании, без предоставления этих данных третьим лицам.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>Сроки и способы обработки персональных данных</h3>
          <ol>
            <li>Обработка персональных данных Пользователя осуществляется без ограничения срока,
              любым законным способом, в том числе в информационных системах персональных данных с
              использованием средств автоматизации или без использования таких средств.
            </li>
            <li>Никакую технологию нельзя считать защищенной от вмешательства или взлома, однако
              Администрация сайта принимает необходимые и достаточные организационные и технические
              меры для защиты персональной информации Пользователя от неправомерного или случайного
              доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от
              иных неправомерных действий с ней третьих лиц, в соответствии с законодательством
              Российской Федерации, а также внутренними регламентами и распорядками.
            </li>
            <li>Компания никогда и ни при каких условиях не передает персональные данные Пользователя
              третьим лицам, для целей использования этой информации в интересах этих третьих лиц, в
              частности (но не ограничиваясь) для целей рассылки рекламной информации, составления баз
              данных и т.д.
            </li>
            <li>
              Компания вправе передавать Персональную информацию третьим лицам в следующих
              случаях:
              <ol>
                <li>Пользователь выразил свое согласие на такие действия, включая случаи применения
                  Пользователем настроек используемого программного обеспечения, не ограничивающих
                  предоставление определенной информации;
                </li>
                <li>В связи с передачей Сайта во владение, пользование или собственность такого третьего
                  лица, включая уступку прав по заключенным с Пользователем договорам в пользу такого
                  третьего лица;
                </li>
                <li>По запросу суда или иного уполномоченного государственного органа в рамках
                  установленной законодательством процедуры;
                </li>
                <li>Для защиты прав и законных интересов Компании в связи с нарушением заключенных с
                  Пользователем договоров.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>Обязательство сторон</h3>
          <ol>
            <li>Пользователь обязан:
              <ol>
                <li>Предоставить информацию о персональных данных, необходимую для пользования
                  Сайтом.
                </li>
              </ol>
            </li>
            <li>
              Администрация сайта обязана:
              <ol>
                <li>Использовать полученную информацию исключительно в целях, указанных в п. 4.1
                  настоящей Политики конфиденциальности.
                </li>
                <li> Обеспечить хранение конфиденциальной информации в тайне, не разглашать без
                  предварительного согласия Пользователя, а также не осуществлять продажу, обмен,
                  опубликование, либо разглашение иными возможными способами персональных данных
                  Пользователя, за исключением случаев, предусмотренных п. 5.4 настоящей Политики
                  конфиденциальности.
                </li>
                <li>Принимать меры предосторожности для защиты конфиденциальной информации
                  Пользователя согласно порядку, обычно используемого для защиты такого рода информации в
                  существующем деловом обороте.
                </li>
                <li>Осуществить блокирование персональных данных, относящихся к соответствующему
                  Пользователю, с момента обращения или запроса Пользователя или его законного представителя
                  либо уполномоченного органа по защите прав субъектов персональных данных на период
                  проверки, в случае выявления недостоверных персональных данных или неправомерных
                  действий.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>Ответственность</h3>
          <ol>
            <li>Администрация сайта несет ответственность за свои обязательства перед Пользователем в
              связи с неправомерным использованием персональных данных в соответствии с
              законодательством Российской Федерации, за исключением случаев, предусмотренных п. 5.4 и
              п. 7.2 настоящей Политики конфиденциальности.
            </li>
            <li>В случае утраты или разглашения конфиденциальной информации Администрация сайта не
              несёт ответственность, если данная конфиденциальная информация:
              <ol>
                <li>Стала публичным достоянием до её утраты или разглашения</li>
                <li>Была получена от третьей стороны до момента её получения Администрацией сайта</li>
                <li>Была разглашена с согласия Пользователя</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>Дополнительные условия</h3>
          <ol>
            <li>Администрация сайта вправе вносить изменения в настоящую Политику
              конфиденциальности без предварительного уведомления Пользователя.
            </li>
            <li>Новая Политика конфиденциальности вступает в силу с момента ее опубликования на
              Сайте, если иное не предусмотрено новой Политикой конфиденциальности.
            </li>
            <li>Действующая Политика конфиденциальности размещена на странице по
              адресу <Link to={"/policy"}>https://paraplancrm.ru/policy</Link></li>
          </ol>
        </li>
      </ol>
    </TextPageComponent>
  </Layout>
)

export default PolicyPage
